import { lazy } from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';

import translationKeys from '../translations/keys';

// Ditch external redirects as soon as portal, web client, etc. have been incorporated into the app
import ExternalRedirect from '../components/external-redirect';

// Icons
import { ReactComponent as CamerasIcon } from '../assets/images/icons/cameras.svg';
import { ReactComponent as EventsIcon } from '../assets/images/icons/motion.svg';
import { ReactComponent as ManageIcon } from '../assets/images/icons/manage.svg';
import { ReactComponent as PlaybackIcon } from '../assets/images/icons/playback.svg';
import { ReactComponent as DashboardIcon } from '../assets/images/icons/dashboard.svg';
import { ReactComponent as VehicleIcon } from '../assets/images/icons/car.svg';
import { ReactComponent as AccountIcon } from '../assets/images/icons/account.svg';
import { ReactComponent as OrdersIcon } from '../assets/images/icons/orders.svg';
import { ReactComponent as PartnerPortalIcon } from '../assets/images/icons/partner-portal.svg';
import { ReactComponent as WhatsNewIcon } from '../assets/images/icons/whats-new.svg';
import { ReactComponent as InstallIcon } from '../assets/images/icons/install.svg';
import ToolsIcon from '../assets/images/icons/tools';

const SignIn = lazy(() => import('./sign-in'));
const Register = lazy(() => import('./register'));
const ForgotPassword = lazy(() => import('./forgot-password'));
const ResetPassword = lazy(() => import('./reset-password'));
const PermissionLevels = lazy(() => import('./permission-levels'));
const AcceptInvite = lazy(() => import('./accept-invite'));
const GetStarted = lazy(() => import('./get-started'));
const Payment = lazy(() => import('./payment'));
const NewPricing = lazy(() => import('./new-pricing'));
const AppRegisterSuccess = lazy(() => import('./app-register-success'));
const PaypalConfirm = lazy(() => import('./paypal-confirm'));
const CloudAdapters = lazy(() => import('./cloud-adapters'));
const WebsiteDown = lazy(() => import('./website-down'));
const Customer = lazy(() => import('./customer'));
const Manage = lazy(() => import('./manage'));
const Account = lazy(() => import('./account'));
const Cameras = lazy(() => import('./cameras'));
const Playback = lazy(() => import('./playback'));
const Events = lazy(() => import('./events'));
const Dashboard = lazy(() => import('./dashboard'));
const CameraSettings = lazy(() => import('./camera-settings'));
const CreateEditCameraGroup = lazy(() => import('./camera-groups'));
const OAuthStart = lazy(() => import('./oauth-start'));
const OAuthEnd = lazy(() => import('./oauth-end'));
const Terms = lazy(() => import('./terms'));
const FullWhiteLabelTerms = lazy(() => import('./terms/FullWhiteLabelTerms'));
const Privacy = lazy(() => import('./privacy'));
const FullWhiteLabelPrivacy = lazy(() => import('./privacy/FullWhiteLabelPrivacy'));
const Install = lazy(() => import('./install'));
const Support = lazy(() => import('./support'));
const Health = lazy(() => import('./health'));
const Vehicles = lazy(() => import('./vehicles'));
const People = lazy(() => import('./people'));

// Takeaways pages
const TakeawayOrders = lazy(() => import('./takeaway-orders'));
const TakeawaySettings = lazy(() => import('./takeaway-settings'));

// Basename for demo
export const DEMO_PATH = '/demo';

// Layout options
export const LAYOUT_FULLSCREEN = 'FULLSCREEN'; // Narrow width, logo, background
export const LAYOUT_NONE = 'NONE'; // Blank canvas
export const LAYOUT_HEADER_FOOTER = 'HEADER_FOOTER'; // Header and footer
export const LAYOUT_APP = 'LAYOUT_APP'; // Sidebar

/*
Each route can have the following options:

- path (string, required): relative URL path to page
- renderComponent (function, required if externalRedirect is not true): function called by App to create page's component. This function gets passed accountData, an object containing functions for manipulating user account data stored in App.jsx, and a boolean indicating whether web app is being accessed through mobile app
- translationKey (string, optional): translation key for the name of this page
- navbar (boolean, optional): include this page in the navbar (header version)
- sidebarNav (boolean, optional): include this page in the 'App layout' sidebar (in the top section)
- sidebarNavSecondary (boolean, optional): include this page in the lower section of the 'App layout' sidebar
- icon (SVG ReactComponent, optional): if `sidebarNav` is true, this is used as the icon
- restrictAccess (array, optional): includes one or more of 'u', 'i', 'd' and 's' depending on which account types can access the page. Exclude this option if page is public
- redirectIfLoggedIn (boolean, optional): if true, page can only be accessed if not logged in (e.g. sign in page)
- layout (string or object, optional): determines layout of page (either with header and footer; sidebar; logo, narrow width; or nothing at all)
    object is structured:
        {
            type: string, one of ['FULLSCREEN', 'NONE', 'LAYOUT_APP', 'HEADER_FOOTER' (default)], optional
            narrow: boolean, only applies to HEADER_FOOTER layouts, sets max width on page
            fullWidth: boolean, only applies to LAYOUT_APP layouts, removes padding from <main>
        }
    if only needing to specify 'type' string, then this can be set as 'layout' rather than an object
- routes (object, optional): subroutes of this route. Object is structured identically to the overall routes object: each key is page name and value is an object with all the same possible properties as in this list
- whiteLabelCondition (function, optional): function which takes white label info as its only argument and returns a boolean. Called once white label info has been fetched. If function returns false, page is deleted from routes (i.e. page is not to appear for this white label). Only uses host info, not installer branding too if on CCTVC
- accountCondition (function, optional): function which takes logged in user's account info as its only argument and returns a boolean indicating whether page can be accessed
- demo (boolean, optional): Whether to include page in white label demo. If true, page must use LAYOUT_APP
- beta (boolean, optional): Show "BETA" next to navlink in sidebar
*/
export const routes = {
    portal: {
        translationKey: translationKeys.navigation.PORTAL,
        path: '/manage',
        navbar: true,
        sidebarNav: true,
        icon: PartnerPortalIcon,
        restrictAccess: ['i', 'd', 's'],
        renderComponent: () => {
            if (/(^localhost|^oanadev\.|^testapp\.)/i.test(window.location.hostname)) {
                return <div style={{ color: '#000' }}><p style={{ margin: 20 }}>In production, web app would now redirect to Partner Portal.</p><a href="/cameras">Go to cameras</a></div>
            }
            return (<ExternalRedirect to="/manage" />);
        },
        layout: LAYOUT_NONE,
        whiteLabelCondition: ({ hidePortalLink }) => !hidePortalLink,
    },

    support: {
        path: '/support',
        layout: LAYOUT_APP,
        renderComponent: () => (
            <Support />
        ),
        restrictAccess: ['s'],
        sidebarNav: true,
        icon: ToolsIcon,
        translationKey: 'Support',
        routes: {
            oldUser: {
                path: '/:uid',
            },
            users: {
                path: '/users',
                routes: {
                    user: {
                        path: '/:uid'
                    }
                }
            },
            installers: {
                path: '/installers',
                routes: {
                    installer: {
                        path: '/:installerId'
                    }
                }
            }
        },
        // Only show for VL (not that it matters otherwise as no one else will have staff logins)
        whiteLabelCondition: ({ isVideoloft }) => isVideoloft
    },
    health: {
        path: '/health',
        layout: LAYOUT_APP,
        renderComponent: () => (
            <Health />
        ),
        restrictAccess: ['s'],
        sidebarNav: true,
        icon: DashboardIcon,
        translationKey: 'Health',
        // Only show for VL (not that it matters otherwise as no one else will have staff logins)
        whiteLabelCondition: ({ isVideoloft }) => isVideoloft
    },

    multicam: {
        // Got this in case I've missed any links to old multicam
        path: '/multicam',
        layout: LAYOUT_NONE,
        renderComponent: () => <Redirect to={"/cameras"} />
    },
    myCameras: {
        translationKey: translationKeys.navigation.MY_CAMERAS,
        path: '/viewer',
        restrictAccess: ['u', 'i', 'd', 's'],
        renderComponent: () => <ExternalRedirect to="/viewer" />,
        layout: LAYOUT_NONE,
    },
    signIn: {
        path: '/sign-in',
        acceptPaths: ['/sign-in.html'],
        layout: LAYOUT_FULLSCREEN,
        redirectIfLoggedIn: true,
        renderComponent: (
            accountData,
            { handleLogin, handleLogout, handleNetworkError },
            mobileApp
        ) => (
            <SignIn
                updateAccountData={handleLogin}
                mobileApp={mobileApp}
                onInvalidToken={handleLogout}
                onNetworkError={handleNetworkError}
            />
        ),
    },
    register: {
        path: '/register',
        layout: LAYOUT_FULLSCREEN,
        redirectIfLoggedIn: true,
        renderComponent: (accountData, { handleLogin, handleLogout, handleNetworkError, }) => (
            <Register updateAccountData={handleLogin} onInvalidToken={handleLogout} onNetworkError={handleNetworkError} />
        ),
    },
    forgotPassword: {
        path: '/forgot-password',
        acceptPaths: ['/forgotten-password.html'],
        layout: LAYOUT_FULLSCREEN,
        renderComponent: (accountData, fs, mobileApp) => (
            <ForgotPassword mobileApp={mobileApp} />
        ),
    },
    resetPassword: {
        path: '/reset-password',
        acceptPaths: ['/reset-password.html'],
        layout: LAYOUT_FULLSCREEN,
        renderComponent: () => (
            <ResetPassword />
        )
    },
    permissionLevels: {
        path: '/permission-levels',
        layout: {
            type: LAYOUT_HEADER_FOOTER,
            narrow: true,
        },
        renderComponent: () => <PermissionLevels />,
    },
    acceptInvite: {
        path: '/acceptinvite',
        layout: LAYOUT_FULLSCREEN,
        renderComponent: (
            { email },
            { handleLogout, handleNewOrganisation }
        ) => (
            <AcceptInvite
                email={email}
                onLogout={handleLogout}
                addOrganisation={handleNewOrganisation}
            />
        ),
    },
    getStarted: {
        path: '/get-started',
        acceptPaths: ['/quote/:id'],
        layout: LAYOUT_NONE,
        whiteLabelCondition: ({ canRegisterUsers }) => canRegisterUsers,
        renderComponent: (
            { uid, email, ssoProvider, isSelfReg, incompleteSelfRegSteps },
            { handleLogin, handleLogout, handleNetworkError, handleCompleteSelfRegStep, logoutAction },
            mobileApp
        ) => (
            <GetStarted
                isSignedIn={!!uid}
                email={email}
                ssoProvider={ssoProvider}
                isSelfReg={isSelfReg}
                incompleteSelfRegSteps={incompleteSelfRegSteps}
                updateAccountData={handleLogin}
                isMobileApp={mobileApp}
                onInvalidToken={handleLogout}
                onNetworkError={handleNetworkError}
                onSelfRegStepCompleted={handleCompleteSelfRegStep}
                onLogoutClick={logoutAction}
            />
        ),
    },
    payment: {
        path: '/payment',
        layout: LAYOUT_FULLSCREEN,
        renderComponent: () => <Payment />,
    },
    newPricing: {
        path: '/new-pricing',
        renderComponent: ({ accountType }) => <NewPricing accountType={accountType} />,
        restrictAccess: ['u', 'i', 'd'],
        layout: LAYOUT_APP
    },
    appRegisterSuccess: {
        path: '/app-register-success',
        layout: LAYOUT_NONE,
        renderComponent: () => <AppRegisterSuccess />,
    },
    paypalConfirm: {
        path: '/paypal-confirm',
        layout: LAYOUT_NONE,
        renderComponent: () => (
            <PaypalConfirm />
        ),
    },
    cloudAdapters: {
        path: '/cloud-adapters',
        acceptPaths: ['/cloud-adapter.html'],
        whiteLabelCondition: ({ canPurchaseHardware }) => canPurchaseHardware,
        renderComponent: ({ accountType }) => (
            <CloudAdapters
                isSignedIn={!!accountType}
                isEndUser={accountType === 'u'}
            />
        ),
    },
    websiteDown: {
        path: '/backup',
        layout: LAYOUT_NONE,
        whiteLabelCondition: ({ appName }) => appName === 'Videoloft',
        renderComponent: ({ email }) => (
            <WebsiteDown isSignedIn={!!email} />
        ),
    },
    addCustomer: {
        path: '/customers/new',
        restrictAccess: ['i'],
        layout: {
            type: LAYOUT_NONE,
        },
        renderComponent: () => (
            <Customer />
        ),
    },
    editCustomer: {
        path: '/customers/:customerUid',
        restrictAccess: ['i'],
        layout: {
            type: LAYOUT_NONE,
        },
        renderComponent: () => (
            <Customer />
        ),
    },

    cameras: {
        path: '/cameras',
        translationKey: translationKeys.cameras.status.LIVE,
        navbar: true,
        sidebarNav: true,
        layout: {
            type: LAYOUT_APP
        },
        restrictAccess: ['u', 'i', 'd', 's'],
        icon: CamerasIcon,
        renderComponent: ({
            uid,
            organisations,
            cameraGroups,
            installAllowed
        }) => (
            <Cameras
                uid={uid}
                organisations={organisations}
                cameraGroups={cameraGroups}
                installAllowed={installAllowed}
            />
        ),
        routes: {
            camera: {
                path: '/:uidd',
            },
            grid: {
                path: '/grid'
            },
            layouts: {
                path: '/layouts',
                routes: {
                    layout: {
                        path: '/:id'
                    }
                }
            },
            presentationMode: {
                path: '/presentations',
                routes: {
                    presentation: {
                        path: '/:id'
                    }
                }
            }
        },
        whiteLabelCondition: ({ isTakeawayVendor }) => !isTakeawayVendor,
        demo: true
    },
    events: {
        path: '/events',
        translationKey: translationKeys.events.EVENTS,
        layout: LAYOUT_APP,
        navbar: true,
        sidebarNav: true,
        restrictAccess: ['u', 'i', 'd', 's'],
        icon: EventsIcon,
        renderComponent: ({
            uid,
            organisations,
            cameraGroups,
            installAllowed
        }) => (
            <Events
                uid={uid}
                organisations={organisations}
                cameraGroups={cameraGroups}
                installAllowed={installAllowed}
            />
        ),
        whiteLabelCondition: ({ isTakeawayVendor }) => !isTakeawayVendor,
        demo: true
    },
    playback: {
        path: '/playback',
        translationKey: translationKeys.playback.PLAYBACK,
        layout: {
            type: LAYOUT_APP
        },
        navbar: true,
        sidebarNav: true, 
        restrictAccess: ['u', 'i', 'd', 's'],
        icon: PlaybackIcon,
        renderComponent: ({ organisations, cameraGroups, installAllowed, uid }) => (
            <Playback
                loggedInUserUid={uid}
                organisations={organisations}
                cameraGroups={cameraGroups}
                installAllowed={installAllowed}
            />
        ),
        // Hide for Manything (to keep down costs) and receipts versions
        whiteLabelCondition: ({ isTakeawayVendor, isManything }) => !isTakeawayVendor && !isManything,
        demo: true,
    },
    dashboard: {
        path: '/dashboard',
        translationKey: translationKeys.navigation.DASHBOARD,
        layout: {
            type: LAYOUT_APP
        },
        navbar: true,
        sidebarNav: true,
        restrictAccess: ['u', 'i', 'd', 's'],
        icon: DashboardIcon,
        renderComponent: ({ uid, cameraGroups, organisations, installAllowed }, { handleNewCameraGroup }) => <Dashboard uid={uid} cameraGroups={cameraGroups} addCameraGroup={handleNewCameraGroup} organisations={organisations} installAllowed={installAllowed} />,
        whiteLabelCondition: ({ isTakeawayVendor, isManything }) => !isTakeawayVendor && !isManything,
        demo: true
    },
    settings: {
        path: '/settings',
        translationKey: translationKeys.technical.settings.SETTINGS,
        layout: {
            type: LAYOUT_APP
        },
        navbar: true,
        sidebarNav: true,
        restrictAccess: ['u', 'i', 'd', 's'],
        icon: ToolsIcon,
        renderComponent: ({ uid, cameraGroups, organisations, installAllowed }) => <CameraSettings uid={uid} cameraGroups={cameraGroups} organisations={organisations} installAllowed={installAllowed} />,
        whiteLabelCondition: ({ isTakeawayVendor, isManything }) => !isTakeawayVendor && !isManything,
        demo: true
    },
    vehicles: {
        path: '/vehicles',
        layout: LAYOUT_APP,
        renderComponent: ({ organisations, cameraGroups, uid }) => (
            <Vehicles
                organisations={organisations}
                cameraGroups={cameraGroups}
                loggedInUserUid={uid}
            />
        ),
        restrictAccess: ['u', 'i', 'd', 's'],
        routes: {
            counts: {
                path: '/counts',
            },
            plate: {
                path: '/:plate'
            }
        },
        sidebarNav: true,
        icon: VehicleIcon,
        translationKey: translationKeys.vehicles.LPR,
        demo: true,
        whiteLabelCondition: ({ isVideoloft, disableVehiclesRoute, isTakeawayVendor, isManything }) => {   
            return (
                !isTakeawayVendor &&
                !isManything &&
                // Don't show /vehicles page for white labels that don't want it or on ANY demos (as none are set up for it yet)
                (isVideoloft || window.location.host.match(/cctvconnect\.com/) || !window.location.pathname.match(/^\/demo(\/|$)/)) &&
                !disableVehiclesRoute
            );
        }
    },
    people: {
        path: '/people',
        layout: LAYOUT_APP,
        renderComponent: ({ organisations, cameraGroups, uid }) => (
            <People
                organisations={organisations}
                cameraGroups={cameraGroups}
                loggedInUserUid={uid}
            />
        ),
        restrictAccess: ['u', 'i', 'd', 's'],
        sidebarNav: true,
        icon: PartnerPortalIcon,
        translationKey: translationKeys.peopleCounting.PEOPLE_COUNTING,
        demo: true,
        whiteLabelCondition: ({ isVideoloft, disablePeopleRoute, isTakeawayVendor, isManything }) => {
            return (
                !isTakeawayVendor &&
                !isManything &&
                // Don't show /people page for white labels that don't want it or on ANY demos (as none are set up for it yet)
                (isVideoloft || !window.location.pathname.match(/^\/demo(\/|$)/)) &&
                !disablePeopleRoute
            );
        }
    },
    takeawayOrders: {
        path: '/orders',
        sidebarNav: true,
        navbar: true,
        layout: LAYOUT_APP,
        restrictAccess: ['u'],
        icon: OrdersIcon,
        translationKey: translationKeys.takeaways.ORDERS,
        renderComponent: ({
            organisations,
        }) => (
            <TakeawayOrders
                kitchens={organisations}
            />
        ),
        whiteLabelCondition: ({ isTakeawayVendor }) => isTakeawayVendor,
    },
    takeawaySettings: {
        path: '/settings',
        sidebarNav: true,
        layout: LAYOUT_APP,
        restrictAccess: ['u'],
        icon: ManageIcon,
        translationKey: translationKeys.technical.settings.SETTINGS,
        renderComponent: ({ organisations }) => (
            <TakeawaySettings
                kitchens={organisations}
            />
        ),
        whiteLabelCondition: ({ isTakeawayVendor }) => isTakeawayVendor
    },
    whatsNew: {
        translationKey: translationKeys.navigation.WHATS_NEW,
        path: '/product-updates',
        sidebarNavSecondary: true,
        icon: WhatsNewIcon,
        renderComponent: () => () => {
            // Use replace as path /product-updates doesn't actually exist within this app
            window.location.replace('https://videoloft.com/product-updates');
            return null;
        },
        layout: LAYOUT_NONE,
        whiteLabelCondition: ({ isVideoloft, isTakeawayVendor }) => isVideoloft && !isTakeawayVendor,
    },
    manage: {
        path: '/manage-plan',
        layout: LAYOUT_APP,
        translationKey: translationKeys.navigation.MANAGE,
        sidebarNavSecondary: true,
        restrictAccess: ['u', 'i', 'd', 's'],
        icon: ManageIcon,
        renderComponent: (accountInfo, fs, mobileApp) => (
            <Manage
                accountInfo={accountInfo}
                mobileApp={mobileApp}
            />
        ),

        // Only show manage page if user has their own cloud plan
        accountCondition: ({ organisations, uid }) => !!organisations.find(org => org.id === uid),

        whiteLabelCondition: ({ isTakeawayVendor }) =>
            !isTakeawayVendor,
        routes: {
            plan: {
                path: '/plan',
                translationKey: translationKeys.plan.PLAN
            },
            billing: {
                path: '/billing',
                translationKey: translationKeys.payments.BILLING,
                accountCondition: ({ isEUP }) => isEUP,
            },
            additionalUsers: {
                path: '/additional-users',
                translationKey: translationKeys.multiuser.ADDITIONAL_USERS,
                routes: {
                    editUser: {
                        path: '/:uid',
                    },
                    newUser: {
                        path: '/new',
                    },
                },
                whiteLabelCondition: ({ isManything }) => !isManything
            },
            analyticsTraining: {
                path: '/ai-training',
                translationKey: translationKeys.analyticsTraining.VIDEOLOFT_AI_TRAINING,
                whiteLabelCondition: ({ isVideoloft }) => isVideoloft
            },
            demoConsent: {
                path: '/demo-consent',
                translationKey: translationKeys.demo.optIn.DEMO_OPT_IN,
                whiteLabelCondition: ({ isVideoloft }) => isVideoloft
            }
        },
    },
    account: {
        path: '/account',
        translationKey: translationKeys.account.ACCOUNT,
        layout: {
            type: LAYOUT_APP,
        },
        restrictAccess: ['u', 'i', 'd', 's'],
        icon: AccountIcon,
        sidebarNavSecondary: true,
        renderComponent: (
            { email, ssoProvider, isSelfReg },
            { handleChangeEmail, handleLogin, handleLogout, handleNetworkError }
        ) => (
            <Account
                email={email}
                updateEmail={handleChangeEmail}
                updateLoginInfo={handleLogin}
                onInvalidToken={handleLogout}
                onNetworkError={handleNetworkError}
                ssoProvider={ssoProvider}
                isSelfReg={isSelfReg}
            />
        ),
    },
    oauthStart: {
        path: '/oauth/:provider/start',
        layout: LAYOUT_NONE,
        renderComponent: () => <OAuthStart type="oauth" />,
        whiteLabelCondition: ({ isVideoloft }) => isVideoloft
    },
    oauthEnd: {
        path: '/oauth/:provider/end',
        layout: LAYOUT_FULLSCREEN,
        renderComponent: () => (
            <OAuthEnd
                type="oauth"
            />
        ),
        whiteLabelCondition: ({ isVideoloft }) => isVideoloft
    },
    ssoStart: {
        path: '/sso/:provider/start',
        layout: LAYOUT_NONE,
        renderComponent: () => <OAuthStart type="sso" />,
        whiteLabelCondition: ({ supportsSSO }) => supportsSSO
    },
    ssoEnd: {
        path: '/sso/:provider/end',
        layout: LAYOUT_FULLSCREEN,
        renderComponent: (accountData, { handleLogin, handleLogout, handleNetworkError }) => (
            <OAuthEnd
                type="sso"
                handleLogin={handleLogin}
                onInvalidToken={handleLogout}
                onNetworkError={handleNetworkError}
            />
        ),
        whiteLabelCondition: ({ supportsSSO }) => supportsSSO,
        redirectIfLoggedIn: true,
    },
    ssoConvertEnd: {
        path: '/sso/:provider/convert/end',
        layout: LAYOUT_FULLSCREEN,
        renderComponent: (accountData, { handleLogin, handleLogout, handleNetworkError }) => (
            <OAuthEnd
                type="sso-convert"
                handleLogin={handleLogin}
                onInvalidToken={handleLogout}
                onNetworkError={handleNetworkError}
            />
        ),
        whiteLabelCondition: ({ supportsSSO }) => supportsSSO,
    },
    cameraGroups: {
        path: '/camera-groups',
        layout: {
            type: LAYOUT_APP
        },
        restrictAccess: ['u', 'i', 'd', 's'],
        renderComponent: ({ organisations, cameraGroups }, { handleNewCameraGroup, handleEditCameraGroup, handleDeleteCameraGroup }) => (
            <CreateEditCameraGroup
                organisations={organisations}
                cameraGroups={cameraGroups}
                addCameraGroup={handleNewCameraGroup}
                deleteCameraGroup={handleDeleteCameraGroup}
                editCameraGroup={handleEditCameraGroup}
            />
        ),
        routes: {
            createCameraGroup: {
                path: '/new'
            },
            editCameraGroup: {
                path: '/:id'
            }
        },
        // Page only available if user has access to at least one camera
        accountCondition: ({ organisations }) => organisations?.find(({ cameras }) => cameras),
        demo: true
    },
    install: {
        path: '/install',
        navbar: true,
        // sidebarNav: true,
        icon: () => <InstallIcon style={{width: 22, height: 22}}/>,
        translationKey: translationKeys.install.INSTALL,
        layout: {
            type: LAYOUT_APP,
            fullWidth: true,
        },
        restrictAccess: ['u', 'i', 's'],
        renderComponent: (
            accountData,
            { handleChangeOwnOrganisation, logoutAction },
            mobileApp
        ) => (
            <Install
                changeUserCamerasData={handleChangeOwnOrganisation}
                onLogoutClick={logoutAction}
                accountData={accountData}
                mobileApp={mobileApp}
            />
        ),
        accountCondition: ({ installAllowed }) => installAllowed,
        whiteLabelCondition: ({ isTakeawayVendor, isManything }) => !isTakeawayVendor && !isManything,
    },
    terms: {
        path: '/terms',
        layout: LAYOUT_NONE,
        demo: true,
        renderComponent: () => (
            <Terms />
        )
    },
    fullWhiteLabelTerms: {
        path: '/terms-of-service',
        layout: LAYOUT_HEADER_FOOTER,
        demo: true,
        whiteLabelCondition: ({ termsPrivacyData }) => termsPrivacyData?.type === 'full-white-label',
        renderComponent: () => (
            <FullWhiteLabelTerms />
        )
    },
    privacy: {
        path: '/privacy',
        layout: LAYOUT_NONE,
        demo: true,
        renderComponent: () => (
            <Privacy />
        )
    },
    fullWhiteLabelPrivacy: {
        path: '/privacy-policy',
        layout: LAYOUT_HEADER_FOOTER,
        demo: true,
        whiteLabelCondition: ({ termsPrivacyData }) => termsPrivacyData?.type === 'full-white-label',
        renderComponent: () => (
            <FullWhiteLabelPrivacy />
        )
    },
};

// Get all paths belonging to a page, including its path, other accepted paths, and all subroutes
const getAllPaths = ({ path, acceptPaths, routes }) => {
    const paths = [path];

    if (acceptPaths) {
        paths.push(...acceptPaths);
    }

    if (routes) {
        for (const page in routes) {
            paths.push(...getAllPaths(routes[page]));
        }
    }

    return paths;
};

// Build up subroute paths, e.g. subroute /additional-users under page /account becomes '/account/additional-users'
// Then add allPaths property to each page's config which is array of all paths that go that page (including subroutes)
const buildPaths = (pageConfig, currentPath) => {
    pageConfig.path = currentPath + pageConfig.path;

    if (pageConfig.routes) {
        for (const page in pageConfig.routes) {
            buildPaths(pageConfig.routes[page], pageConfig.path);
        }
    }

    pageConfig.allPaths = getAllPaths(pageConfig);
};

for (const page in routes) {
    buildPaths(routes[page], '');
}

export default routes;

// Helpers

export const getRoutesWithLayout = (layout) => {
    if (layout === LAYOUT_HEADER_FOOTER) {
        // Treat default layout differently as it may not be specified in route's config
        return _.filter(
            routes,
            ({ layout }) =>
                !(typeof layout === 'object' ? layout?.type : layout) ||
                (layout?.type ?? layout) === LAYOUT_HEADER_FOOTER
        );
    } else {
        return _.filter(
            routes,
            ({ layout: routeLayout }) =>
                (routeLayout?.type ?? routeLayout) === layout
        );
    }
};

// Only returns first layer object (so not specific subroute objects - though they will be included in the `routes` property of the returned object)
export const getRouteByPath = (path) => {
    return _.find(routes, ({ allPaths }) => allPaths.includes(path));
};

// Replaces URL params in path with specified values
export const getPathWithParams = (path, values) => {
    _.forEach(values, (value, param) => {
        path = path.replace(`:${param}`, value);
    });
    return path;
};

// Returns true if user can access page, false otherwise
// Pass two object arguments: route config and account info
// Needs to check both restrictAccess and accountCondition
export const canUserAccessRoute = ({ restrictAccess, accountCondition }, accountInfo) => (!restrictAccess || restrictAccess.includes(accountInfo.accountType)) && (!accountCondition || accountCondition(accountInfo));
