import axios from 'axios';
import _ from 'lodash';

import { logError } from '../utils/errors';
import host, { isDevelopment } from '../utils/getHost';
import { clearLocalStorage } from '../utils/manageStorage';
import { alphabeticalOrderComparison } from '../utils/string';
import { getSelfRegDistributorId, getSelfRegInstallerId, SELF_REG_INSTALLER_IDS } from '../utils/pricing';

import { getCamerasByOwnerUsingRequestData } from './cameras';

import { makePrivateRequestAsManager, makePrivateRequestAsRole, makePrivateRequestAsUser, makePrivateRequestWithData, privateAuthenticatorRequest, publicAuthenticatorRequest } from './managers/requestBuilder';
import { DEFAULT_AUTH } from './managers/servers';
import { splitIntoChunks } from '../utils/requestHelpers';


// Login APIs return account data

// Email/password login
export const login = async (email, password, authenticator) => {
    const { data } = await publicAuthenticatorRequest(
        'POST',
        `/login`,
        {
            data: {
                email,
                password
            }
        },
        authenticator
    );

    // May get directed to another auth
    if (data.location) {
        return await login(email, password, data.location);
    }

    return await handleLoginResponse(data.result);
}

export const ssoLogin = async (provider, idToken, refreshToken, authenticator) => {
    const { data } = await publicAuthenticatorRequest(
        'POST',
        `/login/sso/${provider}`,
        {
            data: {
                id_token: idToken,
                refresh_token: refreshToken
            }
        },
        authenticator
    );

    // May get directed to another auth
    if (data.location) {
        return await ssoLogin(provider, idToken, refreshToken, data.location);
    }

    return await handleLoginResponse(data.result);
}

/**
 * Get account data for demo.
 * @param {string|boolean} region Either string AWS region (e.g. "euwest1") or boolean `true` to pick nearest region.
 * @returns {Promise<object>} Account data.
 */
export const loginWithDemoAccount = async (region) => {

    let selectedRegion = region;
    if (region === true) {
        // Find nearest region
        const { data } = await axios(DEFAULT_AUTH);
        selectedRegion = data.match(/(euwest1|useast1|uswest2)/)?.[0];
    }

    const { data } = await axios(`https://demologin.manything.com/${region ? `?region=${selectedRegion}` : ''}`);

    return await handleLoginResponse(data);
}

// Fetch account data using loginToken
// Also refreshes authToken
// Use for intial login
export const refreshTokenAndGetAccountData = async (
    loginToken,
    seriesId,
    uid,
    authenticator,
    ssoRefreshToken
) => {
    const data = await tokenLogin(authenticator, loginToken, seriesId, uid, ssoRefreshToken);

    return await handleLoginResponse(data);
};

// Refreshes authToken (and managementToken if applicable) and returns new loginToken and authToken (and managementToken)
export const refreshToken = async (
    loginToken,
    seriesId,
    uid,
    authenticator,
    ssoRefreshToken
) => {
    const data = await tokenLogin(authenticator, loginToken, seriesId, uid, ssoRefreshToken);

    return [data.webLogin.loginToken, data.authToken, data.managementToken, data.webLogin.ssoRefreshToken];
}

// Make login request using loginToken
const tokenLogin = async (authenticator, loginToken, seriesId, uid, ssoRefreshToken) => {
    const { data } = await publicAuthenticatorRequest(
        'POST',
        '/login/refresh',
        {
            data: {
                loginToken,
                seriesId,
                uid,
                ssoRefreshToken
            },
        },
        authenticator
    );

    return data.result;
}


const handleLoginResponse = async (response) => {

    // Add extra fields used around app

    // Useful to have EUP flag
    // e.g. can be used by `accountCondition` when testing page routes
    // Old login API puts `provider` in accountInfo but new one doesn't
    response.isEUP = /pro_/.test(response.accountInfo?.provider ?? response.provider);

    // Account type is 'u' for end user, 'i' for installer, 'd' for distributor, or 's' for staff
    // It is used for checking whether the user has access to a page
    // data.managementType should be one of null (end user), 'i', 'd', or 's'
    const managementTypes = ['i', 'd', 's'];
    response.accountType = managementTypes.includes(response.managementType)
        ? response.managementType
        : 'u';

    // Camel case SSO values
    response.ssoProvider = response.sso_provider;
    delete response.sso_provider;
    response.ssoOrganisation = response.sso_organization;
    delete response.sso_organization;

    // Get distributor id where possible
    if (response.distributorid) {
        // Camel case
        response.distributorId = response.distributorid;
        delete response.distributorid;
    }
    if (response.accountType === 'd') {
        // For distributor managers, must get id from scope
        response.distributorId = response.managementScope;
    }

    response.isSelfReg = response.accountType === 'u' && _.values(SELF_REG_INSTALLER_IDS).includes(response.installerid);
    
    // Additional requests

    const requestData = { uid: response.uid, region: response.region, installerId: response.installerid, token: response.authToken };
    
    // Add organisations, camera groups, and any other info required to run app
    [response.organisations, response.cameraGroups] = await Promise.all([
        getOrganisationsUsingLoginData(requestData, response),
        makePrivateRequestWithData(requestData, getCameraGroups)(),
        makePrivateRequestWithData(requestData, getSelfRegStepsIfRequired)(response),
        makePrivateRequestWithData(requestData, getInstallerCamerasIfRequired)(response)
    ]);

    // Format camera groups
    response.cameraGroups = response.cameraGroups.map(group => getCameraGroupObject(group, response.organisations)).sort(({ name: a }, { name: b }) => alphabeticalOrderComparison(a,b));

    return response;
}

// Steps can include 'plan', 'payment' and 'shipping'
const getSelfRegStepsIfRequired = async ({ makePrivateRequest }, accountData) => {
    if (accountData.isSelfReg) {
        try {
            accountData.incompleteSelfRegSteps = (await makePrivateRequest('auth', 'GET', `/account/:uid/selfreg/steps`)).data;
        } catch (error) {
            logError(error, { identifier: 'GET /account/:uid/selfreg/steps'});

            // If this API call fails, better to let all self reg users log in than none
            accountData.incompleteSelfRegSteps = [];
        }
    }
}

// Fetch uidds belonging to installer managers so we can create "Free partner demo" camera group
const getInstallerCamerasIfRequired = async (requestData, accountData) => {
    if (accountData.accountType === 'i' && accountData.accountInfo.hasCameras) {
        const cameras = await getCamerasByOwnerUsingRequestData(requestData, requestData.uid);
        accountData.partnerDemoCameras = _.map(cameras, ({ uidd }) => uidd);
    }
}


// Send reset password email
export const sendResetPasswordEmail = async (email) => {
    const res = await publicAuthenticatorRequest(
        'GET',
        '/lostpassword',
        {
            params: {
                email,
            },
        }
    );

    return res;
};

// Reset password submit
// API returns 401 if link/token expired
export const resetPassword = async (uid, v, newpass) => {
    const res = await publicAuthenticatorRequest(
        'GET',
        '/resetpassword',
        {
            params: {
                uid,
                v,
                newpass
            }
        }
    );

    if(res.data.err) {
        throw new Error(res.data.err);
    }
    return res;
}

export const logout = async (loginToken, seriesId, uid, authenticator) => {
    clearLocalStorage();

    const res = await publicAuthenticatorRequest(
        'GET',
        '/logout',
        {
            params: {
                loginToken,
                seriesId,
                uid,
            },
        },
        authenticator
    );

    if (!res.data.result) {
        throw new Error(res.data.err);
    }
};


// Register

export const registerFromInviteEmailPassword = async (email, password, region, inviteId) => {
    return await register({
        email,
        password,
        marketingEmails: false,
        caller: 'web',
        region,
        inviteId: parseInt(inviteId)
    });
};

export const registerFromInviteSSO = async (provider, idToken, region, inviteId) => {
    return await register({
        ssoProvider: provider,
        idToken,
        marketingEmails: false,
        caller: 'web',
        region,
        inviteId: parseInt(inviteId)
    });
};

export const selfRegisterEmailPassword = (
    email,
    password,
    marketingEmails = false,
    type,
    caller = 'web',
    cameraBrand,
    installerId
) => {
    return selfRegister({
        email,
        password,
        marketingEmails,
        type,
        caller,
        cameraBrand,
        installerId
    });
};

export const selfRegisterSSO = (
    provider,
    idToken,
    marketingEmails = false,
    type,
    caller = 'web',
    cameraBrand,
    installerId
) => {
    return selfRegister({
        ssoProvider: provider,
        idToken,
        marketingEmails,
        type,
        caller,
        cameraBrand,
        installerId
    });
};

const selfRegister = async ({
    email,
    password,
    idToken,
    ssoProvider,
    marketingEmails = false,
    type,
    caller = 'web',
    cameraBrand,
    installerId
}) => {

    if (!installerId) {
        installerId = getSelfRegInstallerId(await getUserLocation());
    }

    return await register({
        ssoProvider,
        email,
        password,
        idToken,
        marketingEmails,
        type,
        caller,
        installerId,
        cameraBrand,
    });
}

const register = async ({
    ssoProvider,
    email,
    password,
    idToken,
    marketingEmails,
    type,
    caller,
    region,
    installerId,
    cameraBrand,
    inviteId,
}) => {
    const location = await getUserLocation();

    // Safari returns language in all lower case (e.g. en-gb)
    // Backend requires region capitalised
    let language = navigator.language;
    let match = language.match(/([a-zA-Z]{2})-([a-zA-Z]{2})/);
    if (match) {
        language = `${match[1].toLocaleLowerCase()}-${match[2].toLocaleUpperCase()}`;
    } else if (language.match(/\d+/)) {
        language = language.substring(0, 2);
    }

    const res = await publicAuthenticatorRequest(
        'POST',
        `/account/register${ssoProvider ? `/${ssoProvider}` : ''}`,
        {
            params: {
                caller
            },
            data: {
                email,
                password,
                idToken,
                language,
                country: location.country_code,
                currency: location.currency.code,
                installerid: installerId,
                inviteid: inviteId,
                marketingemails: marketingEmails,
                subscriberType: type,
                cameraBrand: cameraBrand || undefined
            },
        },
        // Make sure self reg uses Oregon on beta site
        region ?? (isDevelopment ? 'uswest2' : undefined)
    );

    return res;
};

export const partnerRegister = async (
        email,
        password,
        company,
        phoneNo,
        street = '',
        city = '',
        state = '',
        postcode = '',
        country = '',
        convert = false,
        region
    ) => {
        let referral = undefined;
        if(document.cookie) {
            let cookies = document.cookie.split('; ');
            referral = cookies.find(row => row.startsWith("_vldist"))?.split('=')[1];
        }

        const location = await getUserLocation();

        let distributorid;
        if (referral) {
            distributorid = referral;
        } else {
            distributorid = getSelfRegDistributorId(location);
        }
        
        const postData = {
            manageruid: "self",
            distributorid: distributorid,
            token: "self",
            company: company,
            phone: phoneNo,
            email: email,
            password: password,
            streetAddress: street,
            city: city,
            addressRegion: state,
            postCode: postcode,
            country: country,
            createManager: 1,
            currency: location.currency.code,
            countryCode: location.country_code,
        };
        if (convert) {
            postData.convertManager = 1;

            // API also needs this set to 0 because it isn't the brightest
            postData.createManager = 0;
        }

        try {
            const { data } = await publicAuthenticatorRequest(
                'POST',
                `/installers`,
                {
                    data: postData
                },
                isDevelopment ? 'uswest2' : undefined
            );
            return data;
        } catch (err) {
            throw new Error(err.response.data.errorcode);
        }
};

// User location API

// Store location response here
// Only need to call once as result won't change
let location;

// Whether we are currently awaiting response from location API
let fetchingLocation = false;

export const getUserLocation = async () => {
    // Need to stop API being called multiple times
    // If we have already requested location, don't request again
    if (fetchingLocation) {
        return await new Promise((res, rej) => {
            setTimeout(() => getUserLocation().then(res).catch(rej), 50);
        });
    }

    // Fetch location if we don't already have it
    if (!location) {
        try {
            fetchingLocation = true;

            location = (
                await axios.get(
                    'https://api.ipdata.co/?api-key=928e8f8d0ef6483ed1552ec9a128f5e40e7e5a76d47ad9072ee3341b'
                )
            ).data;
        } catch (error) {
            console.error(error);

            // Fallback to US if API not working
            location = {
                country_code: 'US',
                country_name: 'United States',
                currency: {
                    code: 'USD',
                    symbol: '$',
                },
            };
        } finally {
            fetchingLocation = false;
        }
    }
    return location;
};


// Organisations

// Returns list of multiuser accounts that the logged in user has access to
const getOrganisationsUserHasAccessTo = async ({ makePrivateRequest }) => {

    const { data } = await makePrivateRequest(
        'auth',
        'GET',
        `/multiuser/:uid/access/users`
    );

    return data.map(({ alias, maxAge, uid, has_cameras, has_lpr_cameras, has_people_counting_cameras, email }) => ({ name: alias, id: uid, cloudStorageDuration: maxAge, cameras: has_cameras, email  }));
};
export const getOrganisationsLoggedInUserHasAccessTo = makePrivateRequestAsUser(getOrganisationsUserHasAccessTo);
export const getOrganisationsUserHasAccessToAsStaff = makePrivateRequestAsManager(getOrganisationsUserHasAccessTo);

const getOrganisationsUsingLoginData = async (
    requestData,
    {
        uid,
        managementType,
        email,
        accountInfo
    }
) => {
    // Fetch organisations
    let organisations = await makePrivateRequestWithData(requestData, getOrganisationsUserHasAccessTo)();

    // Add user's end user account to front of list
    // Not all users should have their own cameras (even though the way accounts are set up means that they can have their own)
    // In the near future, I want a flag in the db to record whether each user can have their own cameras
    // For now though, just have to bodge specific cases
    let userShouldNotHaveOwnCameras = false;

    // Deliveroo master should not have own cameras
    if (
        organisations.length > 0 &&
        [
            'deliveroo.videoloft.com',
            'deliveroobeta.videoloft.com',
            'receipt.videoloft.com',
            'ordercam.videoloft.com'
        ].includes(host)
    ) {
        userShouldNotHaveOwnCameras = true;
    }

    if (!userShouldNotHaveOwnCameras) {
        let name;
        switch (managementType) {
            case 'i':
                name = 'Free partner demo';
                break;
            case 'd':
                name = 'Distributor demo';
                break;
            default:
                name = email;
                break;
        }
        organisations.unshift({
            id: uid,
            name,
            cloudStorageDuration: accountInfo.maxAge,
            cameras: accountInfo.hasCameras,
            email
        });
    }

    return organisations;
};


// Groups

// Returns list of user's camera groups
// Backend guarantees that all cameras still exist and user still has access to them
const getCameraGroups = async ({ makePrivateRequest }) => {
    const { data } = await makePrivateRequest(
        'auth',
        'GET',
        `/users/:uid/groups`
    );

    return data;
}
export const getCameraGroupsForLoggedInUser = makePrivateRequestAsUser(getCameraGroups);


// Create new camera group
// Returns new group object
export const createCameraGroup = async (groupName, cameraUidds, organisations) => {
    const { data } = await privateAuthenticatorRequest('POST', `/users/:uid/groups`, {
        data: {
            groupName,
            uidd: cameraUidds
        }
    });

    return getCameraGroupObject(data, organisations);
}

// Edit camera group
export const editCameraGroup = (groupId, newGroupName, newCameraUidds) => {

    return Promise.all([
        // Change name
        privateAuthenticatorRequest('PUT', `/users/:uid/groups/${groupId}/name`, {
            data: {
                newName: newGroupName
            }
        }),
        // Change uidds
        privateAuthenticatorRequest('PUT', `/users/:uid/groups/${groupId}`, {
            data: {
                uidd: newCameraUidds
            }
        })
    ]);
}

// Delete camera group
export const deleteCameraGroup = (groupId) => {
    return privateAuthenticatorRequest('DELETE', `/users/:uid/groups/${groupId}`);
}

// `uidds` could be null if cameras have been deleted since group was created
const getCameraGroupObject = ({ uidds, ...group }, organisations) => {
    const cloudStorageDuration = uidds?.reduce((acc, uidd) => {
        const uid = parseInt(uidd.split('.')[0]);
        // At time of writing, groups API may return cameras for which user no longer has access or has been uninstalled
        // So `organisations` may not include its owner
        const durationForThisPlan = organisations.find(({ id }) => id === uid)?.cloudStorageDuration ?? 0;
        return Math.max(durationForThisPlan, acc);
    }, 0);
    return { cameras: uidds ?? [], cloudStorageDuration: cloudStorageDuration ?? 0, ...group };
};

export const getViewerSettingsForCamera = makePrivateRequestAsUser(async ({ makePrivateRequest}, uidd ) => {
    const [ownerUid, deviceId] = uidd.split('.');
    const { data } = await makePrivateRequest(
        'auth',
        'GET',
        `/viewer/${ownerUid}/${deviceId}/settings`
    );

    return data?.result;
});

const getViewerSettingsRequest = async ({ makePrivateRequest}, uidds, uid ) => {
    const splitUidds = splitIntoChunks(uidds);

    // Make API requests for each subarray
    const requests = splitUidds.map(async (uidd) => {
        const { data } = await makePrivateRequest(
        'auth',
        'GET',
        `/viewer/${uid}/settings`,
        {
            params: {
                uidd: uidd,
            }
        }
        );
        return data;
    });
    return Promise.all(requests);
};

export const getAllViewerSettingsForCameras = (uidds, uid, isManager = false) => makePrivateRequestAsRole(isManager)(getViewerSettingsRequest)(uidds, uid);

const getUserSchedulesRequest = async ({ makePrivateRequest}, uidds, userId ) => {
    const splitUidds = splitIntoChunks(uidds);

    // Make API requests for each subarray
    const requests = splitUidds.map(async (uidd) => {
        const { data } = await makePrivateRequest(
        'auth',
        'GET',
        `/schedule/${userId}`,
        {
            params: {
                uidd: uidd,
            }
        }
        );
        return data;
    });
    return Promise.all(requests);
};
export const getUserSchedulesForCameras = (uidds, uid, isManager = false) => makePrivateRequestAsRole(isManager)(getUserSchedulesRequest)(uidds, uid);

export const patchViewerSettingsRequest = async ({ makePrivateRequest }, uidd, settings, uid ) => {
    const { data } = await makePrivateRequest(
        'auth',
        'PATCH',
        `/viewer/${uid}/settings`,
        {
            data: {
                uidd,
                settings
            }
        }
    );

    return data;
};
export const patchViewerSettingsForCameras = (uidds, settings, uid, isManager = false) => makePrivateRequestAsRole(isManager)(patchViewerSettingsRequest)(uidds, settings, uid);

export const getLicensesForUser = makePrivateRequestAsUser(async ({ makePrivateRequest }, uid) => makePrivateRequest('auth','GET',`/account/${uid}/licenses`));